<template>
  <v-card>
    <v-card-title>Assembleia</v-card-title>
    <v-card-text>
      <v-card
        outlined
        class="mb-5"
      >
        <v-card-title class="mb-2">
          Proposta
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="form-space"
            >
              <v-text-field
                v-model="data.client_name"
                readonly
                label="Cliente"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.proposal_number"
                readonly
                label="Proposta"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.group_quota_r_d"
                readonly
                label="Grupo Cota R D"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.assembly_date"
                readonly
                label="Data Contemplação"
                type="date"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.contemplation_type"
                readonly
                label="Tipo Contemplação"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.bid_percentage"
                readonly
                label="Porcentagem Lance"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.bid_value"
                readonly
                label="Lance"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.assembly_number"
                readonly
                label="Nº Assembleia"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.vehicle_model"
                readonly
                label="Modelo"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined>
        <v-card-title class="mb-2">
          Contato
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.phone_home"
                readonly
                label="Residencial"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.phone_commercial"
                readonly
                label="Comercial"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.phone_message"
                readonly
                label="Recado"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="6"
              class="form-space"
            >
              <v-text-field
                v-model="data.cellphone"
                readonly
                label="Celular"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              class="form-space"
            >
              <v-text-field
                v-model="data.vendor_name"
                readonly
                label="Vendedor"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
  },
  created() {
    this.formatFields()
  },
  methods: {
    formatFields() {
      this.data.bid_percentage = `${parseFloat(this.data.bid_percentage).toFixed(2)} %`
      this.data.bid_value = `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
        parseFloat(this.data.bid_value),
      )}`
    },
  },
}
</script>
<style>
.form-space {
  padding: 0 12px;
}
</style>
