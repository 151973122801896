<template>
  <v-card>
    <v-card-title>
      Assembleia
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          md="3"
        >
          <v-select
            v-model="referenceModel"
            dense
            label="Referência"
            outlined
            :items="referenceItems"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span v-if="index === 0">{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ referenceModel.length - 1 }})
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          md="3"
        >
          <v-select
            v-model="typeContemplationModel"
            type="text"
            dense
            label="Assembleia"
            outlined
            :items="typeContemplationItems"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span v-if="index === 0">{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ typeContemplationModel.length - 1 }})
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-row justify="end">
            <v-col cols="auto">
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="filterAssembly()"
              >
                Filtrar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-col cols="12">
      <v-simple-table
        fixed-header
        height="60vh"
      >
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              :class="header.class"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody
          v-if="load.status"
        >
          <tr>
            <td :colspan="headers.length">
              <v-progress-linear
                indeterminate
              />
              <v-row justify="center">
                <v-subheader>
                  {{ load.message }}
                </v-subheader>
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody
          v-else
        >
          <tr
            v-for="item in assemblies"
            :key="item.id"
          >
            <td
              v-for="i in headers"
              :key="i.value"
              :class="i.class"
            >
              {{ item[i.value] }}
              <template v-if="i.text === 'OPÇÕES'">
                <v-btn
                  :loading="item.id === loadModal? true : false"
                  fab
                  color="info"
                  x-small
                  @click="getInfo(item)"
                >
                  <v-icon
                    small
                  >
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="auto">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="5"
            circle
            @input="getCondicaoFilter"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="modal"
      width="900"
    >
      <assembly-modal
        :id="itemId"
        :data="info"
      ></assembly-modal>
    </v-dialog>
  </v-card>
</template>
<script>
import axiosIns from '@axios'
import { mdiPencil } from '@mdi/js'
import AssemblyModal from './AssemblyModal.vue'

export default {
  setup() {
    return {
      mdiPencil,
    }
  },
  components: {
    AssemblyModal,
  },
  data() {
    return {
      modal: false,
      loadModal: '',
      itemId: '',
      info: {},
      filter: 0,
      page: 1,
      totalPages: 0,
      load: {
        status: true,
        message: '',
      },
      assemblies: [],
      referenceModel: [],
      referenceItems: [],
      typeContemplationModel: [],
      typeContemplationItems: [],
      headers: [
        { text: 'MÊS', value: 'reference' },
        { text: 'GRUPO COTA R D', value: 'group_quote_r_d' },
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'TIPO CONTEMPLACAO', value: 'contemplation_type' },
        { text: 'MODELO', value: 'vehicle_model' },
        { text: 'PERC', value: 'bid_percentage', class: 'text-right' },
        { text: 'LANCE', value: 'bid_value', class: 'text-right' },
        { text: 'OPÇÕES' },
      ],
    }
  },
  created() {
    this.next()
    this.getReference()
    this.getTypeContemplation()
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    async getInfo(item) {
      this.loadModal = item.id
      this.itemId = item.id
      await axiosIns.get(`/api/v1/integration/assembly/edit/${item.id}`).then(async res => {
        this.info = res.data.data
        this.modal = true
        this.loadModal = ''
      })
    },
    next() {
      this.assemblies = []
      this.filter = 1
      this.load = {
        status: true,
        message: 'Carregando dados... Por favor, aguarde',
      }
      axiosIns
        .get(`/api/v1/integration/assembly/index?page=${this.page}`)
        .then(res => {
          res.data.data.map(dt => {
            this.assemblies.push({
              id: dt.id,
              reference: dt.reference ? dt.reference : '',
              group_quote_r_d: dt.group_quota_r_d ? dt.group_quota_r_d : '',
              client_name: dt.client_name ? dt.client_name : '',
              contemplation_type: dt.contemplation_type ? dt.contemplation_type : '',
              vehicle_model: dt.vehicle_model ? dt.vehicle_model : '',
              bid_percentage: dt.bid_percentage
                ? `${
                  parseFloat(dt.bid_percentage) % 10
                    ? parseFloat(dt.bid_percentage)
                    : parseFloat(dt.bid_percentage).toFixed(2)
                } %`
                : '',
              bid_value: dt.bid_value
                ? `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                  parseFloat(dt.bid_value).toFixed(2),
                )}`
                : '',
            })
          })
          this.load.status = false
          this.totalPages = res.data.meta.last_page
        })
        .catch(() => {
          this.load = {
            status: true,
            message: 'Erro ao buscar dados... Por favor, recarregue a página',
          }
        })
    },

    async getReference() {
      await axiosIns.get('/api/v1/integration/assembly/list_references').then(res => {
        res.data.data.map(async data => {
          this.referenceItems.push(data.reference)
        })
      })
    },

    async getTypeContemplation() {
      await axiosIns.get('/api/v1/integration/assembly/type_contemplation').then(res => {
        res.data.data.map(async data => {
          this.typeContemplationItems.push(data.contemplation_type)
        })
      })
    },

    async filterAssembly() {
      this.assemblies = []
      this.filter = 2
      this.load = {
        status: true,
        message: 'Carregando dados... Por favor, aguarde',
      }
      const inputData = {
        filtros: {
          reference: this.referenceModel,
          contemplation_type: this.typeContemplationModel,
        },
      }

      this.assemblies.splice(0)
      await axiosIns.post('/api/v1/integration/assembly/assembly_filter/', inputData).then(res => {
        res.data.data.map(async dt => {
          this.assemblies.push({
            id: dt.assemblies_id,
            reference: dt.reference ? dt.reference : '',
            group_quote_r_d: dt.group_quota_r_d ? dt.group_quota_r_d : '',
            client_name: dt.client_name ? dt.client_name : '',
            contemplation_type: dt.contemplation_type ? dt.contemplation_type : '',
            vehicle_model: dt.vehicle_model ? dt.vehicle_model : '',
            bid_percentage: dt.bid_percentage
              ? `${
                parseFloat(dt.bid_percentage) % 10
                  ? parseFloat(dt.bid_percentage)
                  : parseFloat(dt.bid_percentage).toFixed(2)
              } %`
              : '',
            bid_value: dt.bid_value
              ? `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                parseFloat(dt.bid_value).toFixed(2),
              )}`
              : '',
          })
        })
        this.load.status = false

        this.totalPages = res.data.meta.last_page
      })
    },

    async getCondicaoFilter() {
      if (this.filter === 1) {
        this.next()
      }
      if (this.filter === 2) {
        this.filterAssembly()
      }
    },
  },
}
</script>
